.top-bar {
    background-color: #1a1a1a; /* 浅灰色背景 */
    color: #f0f0f0; /* 深灰色字体 */
    height: 60px;
    display: flex;
    justify-content: flex-start; /* Logo靠左对齐 */
    align-items: center;
    padding: 0 20px; /* 两侧的内边距 */
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1); /* 细微阴影效果 */
}

/* 更新logo和按钮的样式，以匹配新的顶部导航栏风格 */
.logo {
    font-size: 1.5em;
    font-weight: bold;
    padding-left: 10px; /* 如果需要，添加左侧内边距 */
    cursor: pointer;
    user-select: none; /* 阻止文字被选中 */
}

.top-bar-content {
    width: 100%; /* 占据父元素的全部宽度 */
    padding: 0 20px; /* 如果不想有padding，可以设置为0 */
    display: flex;
    justify-content: space-between; /* 确保子元素分布在两端 */
    align-items: center;
}

.logout-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007aff; /* 苹果蓝 */
    border: none;
    border-radius: 14px; /* 圆角 */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease; /* 平滑过渡效果 */
    outline: none; /* 移除焦点轮廓 */
    box-shadow: 0 4px 12px rgba(0, 122, 255, 0.5); /* 添加阴影 */
}

.logout-button:hover,
.logout-button:focus {
    background-color: #005ecb; /* 鼠标悬停或焦点时颜色加深 */
}

.logout-button:active {
    background-color: #004c99; /* 鼠标点击时颜色更加深 */
    box-shadow: 0 2px 8px rgba(0, 122, 255, 0.5); /* 点击时阴影更小 */
}
