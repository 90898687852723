/* LoginForm.css */
body,
html {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, sans-serif;
    background-color: #f2f2f7; /* Apple uses a very light grey background */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.login-container {
    width: 20%; /* 宽度占据中间1/5 */
    min-width: 300px; /* 设置一个最小宽度，避免表单太窄 */
    background: white;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 细微的阴影效果 */
    border-radius: 12px; /* 圆角 */
}

.login-form h2 {
    color: #000;
    margin-bottom: 2rem;
}

.form-group {
    margin-bottom: 2rem;
}

.form-group label {
    display: block;
    margin-bottom: 1rem;
    color: #000;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: none; /* 去除边框 */
    border-radius: 8px;
    background-color: #f2f2f7; /* 和背景色相同的填充颜色 */
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* 内阴影效果 */
}

.form-group input:focus {
    outline: none;
    background-color: #e8e8ee; /* 聚焦时的背景色 */
}

button {
    background-color: #007aff; /* Apple's accent color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%; /* 使按钮宽度与输入框相同 */
    font-size: 16px;
}

button:hover {
    background-color: #005ecb;
}

.error-message {
    color: #ff3b30; /* Apple's error color */
}
