/* BitfinexFunding.css */
.date-picker {
    flex-grow: 1; /* 使日期选择器占据剩余空间 */
}

.button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.button {
    background-color: #007aff;
    color: white;
    border: none;
    padding: 2px 3px;
    margin: 0 5px;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.chart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* 可以根据需要调整间隙 */
}

.date-selection {
    display: flex;
    align-items: center;
    gap: 10px; /* 按钮之间的间隔 */
}

.selected {
    background-color: white; /* 白色背景 */
    color: #007aff; /* 蓝色文字 */
    border: 1px solid #007aff; /* 添加边框以保持可见性 */
}

.data-cards-container {
    display: flex;
    flex-direction: row; /* 使卡片垂直排列 */
    align-items: flex-start; /* 使卡片靠左对齐 */
    flex-wrap: wrap; /* 当空间不足时自动换行 */
}

.data-card {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: 200px; /* 最小宽度，可根据需要调整 */
    max-width: 100%; /* 确保卡片不会超过容器宽度 */
}
