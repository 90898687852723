/* 应用于Dashboard组件，确保使用全部高度 */
.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh; /* 使用全部视口高度 */
    width: 100vw; /* 使用全部视口宽度 */
    overflow: hidden;
}

/* 用于Dashboard的主要内容区域 */
.dashboard-main {
    display: flex;
    flex-grow: 1; /* 允许内容区域占据所有可用空间 */
    overflow: hidden; /* 防止内部内容溢出 */
}

/* 确保Sidebar组件不会被压缩或拉伸 */
.sidebar {
    flex-shrink: 0;
}

/* ContentArea组件应该占据剩余的所有空间 */
.content-area {
    flex-grow: 1; /* 允许内容区域占据所有可用空间 */
    overflow-y: auto; /* 如果内容超出视图高度，允许滚动 */
}
