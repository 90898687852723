.sidebar {
    background-color: #1a1a1a; /* 浅灰色背景，与顶栏统一 */
    color: #f0f0f0; /* 深灰色字体，与顶栏统一 */
    height: calc(100vh - 60px); /* 减去顶部栏的高度 */
    width: 16vw;
    padding: 20px; /* 内边距 */
    box-sizing: border-box;
}

.sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 0; /* 移除默认的外边距 */
}

.sidebar-menu li {
    color: #f0f0f0; /* 与侧边栏文字颜色保持一致 */
    cursor: pointer;
    text-decoration: none;
    display: block;
    user-select: none; /* 阻止文字被选中 */
    border-bottom: 1px solid #eaeaea; /* 添加分隔线 */
}

.sidebar-menu li:hover {
    background-color: #3d3d3d; /* 鼠标悬停时的背景色 */
}

.sidebar-menu-item {
    padding: 10px 20px;
}

.sub-menu-item {
    padding: 10px 40px;
}
